.player-card {
    background-color: #f1f1f1;
}

.new-player-card {
    background-color: #fcfcfc;
}



/* Sorteggi */

.clickable-row {
    cursor: pointer;
}