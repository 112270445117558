header.ant-layout-header{
    line-height: 30px;
    box-shadow: 5px 5px 5px 5px black;
}

.menu-header-inner{
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
    padding: 5px;
    border-radius: 5px;
}

aside.ant-layout-sider{
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
}

.sidebar-logo-txt{
    text-align: center;
    font-weight: bold;
    margin: 10px 0;
    /*border-bottom: 1px solid gray;*/
}


div.header-content .title {
    position: absolute;
    left: 5px;
    font-size: 1em;
    font-weight: 400;
}

footer.footer-layout{
    /*border: 1px solid gray;*/
    padding: 20px 10px;
}